import React from "react";
import "./siteInfo.css"
const Wellcome = ()=>{
    return (
        <div className="wellcome-conatiner">
            <h3 className="title2">Velkommen til salon Beauty Klip </h3>
            <p className="wellcome-text">
            Salon Beauty Klip er en frisørsalon som ligger på Naksov, Søndergade 49.
             Vi tilbyder vores kunder kvalitetstid med fokus på personlig rådgivning,
              hvor der bliver lyttet til dine ønsker og behov – og vi er altid klar til at hjælpe dig så klipningen,
               farvningen eller opsætningen passer til din ansigtsform, hudtone og ikke mindst din personlighed.
                Vi lægger vægt på at give kunden den bedste service 
                og går op I at kunden får en god oplevelse I behagelige og hjemmelige omgivelser.
            </p>
        </div>
    )
}

export default Wellcome;