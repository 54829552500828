import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "./slider.css";

const ImageSlider =()=>{
    return(
<Carousel>
  <Carousel.Item interval={2000}>
    <div className="d-block w-100 img1">
    </div>
  </Carousel.Item>
  <Carousel.Item interval={500}>
        <div className="d-block w-100 img2">
        </div>
  </Carousel.Item>
  <Carousel.Item>
       <div className="d-block w-100 img3">
        </div>

  </Carousel.Item>
</Carousel>
    );
}
export default ImageSlider;