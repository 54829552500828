import NavBar from "./components/NavBar/NavBar";
import ImageSlider from "./components/FullSlider/ImageSlider";
import SiteInfoContainer from "./components/SiteInfo/SiteInfoContainer";
import Footer from "./components/Footer/Footer";

import "bootstrap/dist/css/bootstrap.min.css"


function App() {
  return (
    <div className="app">
      <NavBar/>
      <ImageSlider/>
      <SiteInfoContainer/>
      <Footer/>
    </div>
  );
}

export default App;
