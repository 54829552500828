import React from "react";
import "./footer.css";

const Footer=()=>{
    return (
        <div className="footer-caontainer">
            <div className="contact">
            <h3 id="contact" className="title">Kontakt</h3>
                <p className="cantact-info">Mobil nr.: +45 25144677</p>
                <p className="cantact-info">Tlf.nr.: 34111113</p>
                <p className="cantact-info">Addresse: Naksov, Søndergade 49</p>
            </div>
            <div className="opening-houres">
                <h3 id="opening-houres"  className="title">Åbningstider</h3>
                <div className="opening-houres-item">
                    <p >Mandag</p> <p>   8-17</p>
                </div>
                <div className="opening-houres-item">
                    <p>Tirsdag</p> <p>   8-17</p>
                </div>
                <div className="opening-houres-item">
                    <p>Onsdag</p> <p>    8-17</p>
                </div>
                <div className="opening-houres-item">
                    <p>Torsdag</p> <p>   8-17</p>
                </div>
                <div className="opening-houres-item">
                    <p>Lørdag</p> <p>    9-13</p>
                </div>
                <div className="opening-houres-item">
                    <p>Søndag</p> <p>    Lukket</p>
                </div>
            </div>
            <div>
                <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2298.1652939393025!2d11.13739801627665!3d54.82978108031656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b2a85cb3b03e7b%3A0xe3225cf47bf47caf!2sS%C3%B8ndergade%2049%2C%204900%20Nakskov!5e0!3m2!1sda!2sdk!4v1618765801257!5m2!1sda!2sdk"
                 width="300" height="350" style={{border:0}} allowFullScreen="" loading="lazy"></iframe>
            </div>
        </div>
    )
}

export default Footer;