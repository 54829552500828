import React from "react";
import Table from "react-bootstrap/Table";


const PriceTable =()=>{
    return(
      <Table striped bordered hover className="price-table-content">
        <thead>
          <tr>


          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Klipning</td>
            <td>280 kr</td>

          </tr>
          <tr>
            <td>Farvning</td>
            <td>369 kr</td>

          </tr>
          <tr>
            <td>Permanent uden klip</td>
            <td>fra 629 kr</td>

          </tr>
          <tr>
            <td>Reflekser kort hår</td>
            <td>fra 610 kr</td>

          </tr>
          <tr>
            <td>Staniolstriber</td>
            <td>fra 610 kr</td>

          </tr>
          <tr>
            <td>Bryn pluk og farve</td>
            <td>180 kr</td>

          </tr>
          <tr>
            <td>Tilæg for langt hår</td>
            <td>fra 100 kr</td>

          </tr>
        </tbody>
      </Table>
    );
}
export default PriceTable;