import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import "./navbar.css";

const NavBar =()=>{
    return (
<Navbar collapseOnSelect expand="lg" bg="light" variant="light" className="navbar">
  <Navbar.Brand href="#home">Beauty Klip</Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mr-auto">
    </Nav>
    <Nav className="item">
        <Nav.Link href="#prisliste">
            Prisliste
        </Nav.Link>
        <Nav.Link eventKey={2} href="#opening-houres">
            Åbningstider
        </Nav.Link>
        <Nav.Link eventKey={2} href="#contact">
            Kontakt
        </Nav.Link>
    </Nav>
  </Navbar.Collapse>
</Navbar>
    )
}

export default NavBar;