import React from "react";
import PriceTable from "./PriceTable";
import Wellcome from "./Wellcome";
import "./siteInfo.css";

const SiteInfoContainer =()=>{
    return(
        <div className="info-container">
            <div className="content">
                <Wellcome/>
                <div className="pic"></div>
                <div className="price-conatiner">
                    <div className="price-table">
                        <h3 className="title2" id="prisliste">Prisliste</h3>
                        <PriceTable/>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SiteInfoContainer;